import { computed, Inject, inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSerializer } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { last } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class UtmService {
  #route = inject(ActivatedRoute);
  #router = inject(Router);
  #serializer = inject(UrlSerializer);

  #utmList = signal<Record<string, string | undefined>>({});

  readonly utmQuery = computed(() => {
    return this.#serializer.serialize(
      this.#router.createUrlTree(['/'], { queryParams: this.#utmList() })
    );
  });

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.#utmList.set(this.#route.snapshot.queryParams);

    this.#router.events
      .pipe(
        filter((events) => events instanceof NavigationEnd),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        if (isPlatformBrowser(platformId)) {
          const content = last(location.pathname.split('?')[0].split('/'));
          this.#utmList.update((utmList) => ({
            ...utmList,
            utm_page_target: content ? content : 'main'
          }));
        }
      });
  }
}

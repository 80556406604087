import { environment } from '../../../environments/environment';

export const wizardUrl = (isMain?: boolean) => {
  return `${environment.protocol}://${isMain ? environment.mainHost : environment.host}`;
};

export const wizardSocketUrl = () => {
  const port = environment.socket.port ? `:${environment.socket.port}` : '';
  return `${environment.protocol}://${environment.host}${port}`;
};
